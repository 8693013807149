define(["app", "siteObj", "productQuickbuy", "productQuickbuySimple", "papBanner", "cookie", "$window"], function (app, siteObj, productQuickbuy, productQuickbuySimple, papBanner, cookie, $window) {
  const qubitProductPageRecs = () => {
    const component = {};
    const language = siteObj.siteDefaultLocale.replace(/_/g, "-");
    const siteUrl = $window.location.host;
    const qubitCookieName = '_qubitTracker';
    let attempts = 0;
    let productBlockButtonText = '';
    if (
      !((window.dataLayer[0].pageAttributes[0].experiments !== undefined && window.dataLayer[0].pageAttributes[0].experiments.some(e => e.value === 'v1|show_PB_updated_text'))
      || 
      (new URLSearchParams(window.location.search).get('showPbUpdatedText') === 'true'))
      ) {
        productBlockButtonText = siteObj.props.qubitRecs.quickbuyText;
    } else {
      productBlockButtonText = 'Buy Now';
    }

    component.config = {
      selectors: {
        pdpComponent: '.qubitProductPageComponent',
        placementId: 'data-placement',
        productTheme: 'data-product-theme',
        productId: '[data-product-id]',
        productName: '[data-product-name="title"]',
        qubitSectionRecs: '.qubitProductPageRecs',
        fallbackSectionPeek: '.qubitProductPageRecs_fallback',
        productBlockActions: '[data-product-actions]',
        papBannerContainer: '[data-pap-block]',
        productBlock: '[data-product-block]',
        popupLink: '[data-js-element=popupLink]',
        papPopupContents: '[data-papPopup-contents]',
        productQuickbuy: '[data-open-product-quickbuy]',
        productQuickbuySimple: '[data-component=productQuickbuySimple]',
        clickThrough: '[data-placement] a, [data-placement] button',
        onViewPort: '[data-impression]'
      },
      urls: {
        qubitUrl: 'https://api.qubit.com/placements/query',
        qubitTrackingUrl: 'https://api.qubit.com/placements/cb?',
        horizonUrl: `https://horizon-api.${siteUrl}/graphql` // Dynamic URL `https://horizon-api.${siteUrl}/graphql`
      }
    };

    component.init = (element) => {
      component.element = element;
      component.placementId = element.getAttribute(component.config.selectors.placementId);
      component.productTheme =  element.getAttribute(component.config.selectors.productTheme);
      component.productName = document.querySelector(component.config.selectors.productName).textContent;

      let theme;

      if (component.productTheme === 'athena' &&
        (window.dataLayer[0].pageAttributes[0].experiments[0] !== undefined &&
          window.dataLayer[0].pageAttributes[0].experiments[0].value !=='v2|new_navbar_and_config' ||
          window.dataLayer[0].pageAttributes[0].experiments[0].value =='c|athenaProductBlock' )) {
        theme = 'athenaProductBlock';
      } else {
        theme = 'productBlock';
      }

      try {
        if(
          cookie.get('actualOptanonConsent') && cookie.get('actualOptanonConsent').includes('C0002') ||
          $window.cookieName === 'actualOptanonConsent' && $window.cookieValue.includes('C0002')
        ){
          component.makeRequest(component.placementId, component.productName, theme);
        }else{
          component.getCookieConsent();
        }
      } catch (error) {
        console.log('OneTrust not Found')
      }

    };

    component.makeRequest = (placementId, productName, theme) => {
      let getVisitorId = '';
      let placementMode = 'LIVE';
      let queryString = $window.location.search;
      let queryHash = $window.location.hash;
      let queryParams = (queryString) ? queryString : queryHash;
      const urlPreviewParams = (queryParams) ? new URLSearchParams(queryParams) : null;
      const getPreviewMode = (urlPreviewParams) ? urlPreviewParams.get('qb_campaign_id') : null;
      const getSampleMode = (urlPreviewParams) ? urlPreviewParams.get('qb_mode') : null;

      if(getPreviewMode || getSampleMode){
        let getQbplacementId = urlPreviewParams.get('qb_placement_id');
        placementId = getQbplacementId;

        if(getPreviewMode){
          placementMode = 'PREVIEW';
        }
        if(getSampleMode){
          placementMode = getSampleMode;
        }
      }

      if (cookie.get(qubitCookieName)) {
        getVisitorId = cookie.get(qubitCookieName);
      };

      const qubitQuery = `query PlacementContent (
        $mode: Mode!
        $placementId: String!
        $previewOptions: PreviewOptions
        $attributes: Attributes!
        $resolveVisitorState: Boolean!
      ) {
        placementContent(
          mode: $mode
          placementId: $placementId
          previewOptions: $previewOptions
          attributes: $attributes
          resolveVisitorState: $resolveVisitorState
        ) {
          content
          callbackData
          visitorId
        }
      }`;

      const qubitVariables = {
        mode: placementMode,
        placementId: placementId,
        resolveVisitorState: true,
        attributes: {
          visitor: {
            id: getVisitorId,
            url: siteObj.siteURL,
          },
          user: {},
          product: {
            'id': siteObj.productID,
            'name': productName,
          },
          view: {
            currency: siteObj.currencyType,
            type: 'product',
            subtypes: [],
            language: language,
          },
        }
      }

      if(getPreviewMode){
        const getQbcampaignId = urlPreviewParams.get('qb_campaign_id');
        const getQbgroup = urlPreviewParams.get('qb_group');
        const getQbexperienceId = urlPreviewParams.get('qb_experience_id');
        let previewOptions = { campaignId: getQbcampaignId, group: getQbgroup, experienceId: getQbexperienceId }
        qubitVariables['previewOptions'] = previewOptions;
      }

      component.getQubitData(qubitQuery, qubitVariables).then((response) => {
        if(!response.data.placementContent){
          return;
        }
        const qubitSectionRecs = document.querySelector(component.config.selectors.qubitSectionRecs);
        const fallbackSectionPeek = document.querySelector(component.config.selectors.fallbackSectionPeek);
        const items = response.data.placementContent.content;
        const setVisitorId = response.data.placementContent.visitorId;
        const callbackData = response.data.placementContent.callbackData;

        component.setCookie(setVisitorId);
        component.impression(callbackData, component.config.selectors.onViewPort);

        if(items){
          if(!items.recs){
            return;
          }
          fallbackSectionPeek.remove();
          const productIds = component.getQueryStrings(items.recs.map(item => item.details.id));
          component.getHorizonData(productIds).then((response) => {
            component.createMarkup(items, response.data, theme);
            try {
              siteObj.methods.qubitReinitialiseQuickbuy(component.config.selectors.qubitSectionRecs);
            } catch (TypeError) {
              component.reinitialiseQuickbuy(component.config.selectors.productBlockActions);
            }
            component.reinitialisePap();
            component.impressionProducts(callbackData, placementId);
            component.clickThrough(callbackData, theme);
          });
        }else{
          qubitSectionRecs.remove();
          if(fallbackSectionPeek){
            component.impressionProducts(callbackData, fallbackSectionPeek.getAttribute(component.config.selectors.placementId));
            component.clickThrough(callbackData, theme);
          }
        }
      });
    };

    // Get qubit product data
    component.getQubitData = (query, variables) => {
      return new Promise(resolve => {
        fetch(component.config.urls.qubitUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'text/plain' },
          body: JSON.stringify({
            query: query,
            variables: variables,
          }),
        })
          .then((res) => {return resolve(res.json())})
          .catch((error) => {
            console.log(error);
          });
      });
    }

    // Get product color variants from horizon
    component.getHorizonData = (productIds) => {
      return new Promise(resolve => {
        fetch(component.config.urls.horizonUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: productIds
          }),
        })
          .then((res) => {return resolve(res.json())})
          .catch((error) => {
            console.log(error);
          })
      });
    }

    //Graphql Query to get product color variants
    component.getQueryStrings = (productIds) => {
      const products = productIds.map((id) => `
         product_${id}: product(sku: ${id}, strict: false) {
            marketedSpecialOffer {
                title {
                    content {
                        content
                    }
                }
                description {
                    content {
                        content
                    }
                }
                landingPageLink {
                  text
                  url
                }
            }
            variants {
                inStock
                product {
                    sku
                    url
                }
                choices {
                    optionKey
                    key
                    colour
                    title
                }
            }
        }
        `).join('');

      return `query Products {
        ${products}
      }`;
    }

    component.reinitialiseQuickbuy = (selector) => {
      const qubitDOM = document.querySelector(selector);
      if (qubitDOM) {
        const quickbuyButtons = qubitDOM.querySelectorAll(component.config.selectors.productQuickbuy);
        const quickbuySimpleButtons = qubitDOM.querySelectorAll(component.config.selectors.productQuickbuySimple);
        quickbuySimpleButtons && quickbuySimpleButtons.forEach(quickbuySimpleButtons => {
          new productQuickbuySimple().init(quickbuySimpleButtons)
        });
        quickbuyButtons && quickbuyButtons.forEach(quickbuyButtons => {
          new productQuickbuy().init(quickbuyButtons)
        });
      }
    }

    component.reinitialisePap = () => {
      const papDOM = document.querySelectorAll(component.config.selectors.papBannerContainer);
      papDOM && papDOM.forEach(papDOM => {
        new papBanner().init(papDOM)
      });
    }

    component.extractPapAttribute = (item) => {
      if(item){
        const papMarkup = item.replace(/\\/g, "");
        let htmlConvert = document.createElement('div');
        htmlConvert.innerHTML = papMarkup;
        return htmlConvert.innerHTML;
      }
      return '';
    }

    // Athena product markup
    component.createMarkup = (items, itemVariants, theme) => {
      const recsComponent = document.querySelector(component.config.selectors.qubitSectionRecs);

      if (document.querySelector(".qubitProductPageRecs_item")) {
        return;
      }

      const productList = items.recs.map(item => {
        return `
          <li class="qubitProductPageRecs_item">
            <div class="${theme}" data-product-block>
              <div class="${theme}_imageContainer">
                <a class="${theme}_linkImage" href="${item.details.url}" data-product-id="${item.details.id}" data-event-type="clickthrough">
                  <img src="${item.details.image_url}" class="${theme}_image " data-track="product-image" loading="lazy" alt=" ${item.details.name}" height="300" width="300">
                </a>
                ${(theme !== "productBlock") ? component.quickBuy(item, theme) : ''}
              </div>
              <a class="${theme}_linkImage" href="${item.details.url}" data-product-id="${item.details.id}" data-event-type="clickthrough">
                <div class="${theme}_title">
                  <h3 class="${theme}_productName" data-track="product-title">
                    ${item.details.name}
                  </h3>
                </div>
              </a>
              ${(theme === "productBlock") ? component.papBanner(item, itemVariants, theme) : ''}
              ${(item.details.review_count) ? component.reviewStars(item, theme) : ''}
              ${component.colorVariants(itemVariants, item.details.id, theme)}
              ${(theme !== "productBlock") ? component.papBanner(item, itemVariants, theme) : ''}
              ${component.price(item, theme)}
              ${(theme === "productBlock") ? component.quickBuy(item, theme) : ''}
            <div>
          </li>`}).join("");

      const markup = `
        <h2 class="qubitProductPageRecs_title qubitProductPageRecs_title-center componentWidget_title" data-headline="title">${items.headline}</h2>
        <div class="qubitProductPageRecs_grid">
          <ul class="qubitProductPageRecs_list" data-list>
            ${productList}
          </ul>
          <div class="qubitProductPageRecs_clear"></div>
        </div>
      `;

      return recsComponent.insertAdjacentHTML('afterbegin', markup)
    }

    //Product Pricing
    component.price = (item, theme) => {
      const hasPreviousPrice = Number(item.details.unit_sale_price) !== Number(item.details.rrp);
      const saveUpTo = Number(item.details.rrp - item.details.unit_sale_price).toFixed(2);
      const currencyOptions = {
        style: 'currency',
        currency: siteObj.currencyType
      };
      const formattedPrice = new Intl.NumberFormat(language, currencyOptions).format(Number(item.details.unit_sale_price))
      const formattedOriginalPrice = new Intl.NumberFormat(language, currencyOptions).format(Number(item.details.rrp))
      const formattedSaveUpTo = new Intl.NumberFormat(language, currencyOptions).format(Number(saveUpTo))

      return `
        <div class="${theme}_priceBlock">
          ${(hasPreviousPrice) && (!siteObj.config.qubitHasFromPrice) && (Number(item.details.rrp)) ?`
            <div class="${theme}_rrp">
              <span class="${theme}_rrpLabel">${siteObj.props.qubitRecs.rrpText}</span>
              <span class="${theme}_rrpValue">${formattedOriginalPrice}</span>
            </div>
          ` : ''}
          ${(!siteObj.config.qubitHasFromPrice) ? `
          <div class="${theme}_price">
            <span class="${theme}_priceValue">${formattedPrice}</span>
          </div>
          ` : ''}
          ${(siteObj.config.qubitHasFromPrice) && (item.details.has_child_products >= 1) ? `
          <div class="${theme}_from">
            <span class="${theme}_fromLabel">${siteObj.props.product.priceFrom}&nbsp;</span>
            <span class="${theme}_fromValue">${formattedPrice}</span>
          </div>
          ` : (siteObj.config.qubitHasFromPrice) ? `<div class="${theme}_price">
          <span class="${theme}_priceValue">${formattedPrice}</span>
         </div>`: ''}
          ${(hasPreviousPrice) && (siteObj.config.qubitHasFromPrice) && (Number(item.details.rrp)) ? `
            <div class="${theme}_save">
              <span class="${theme}_saveLabel">${siteObj.props.product.priceSave}&nbsp;</span>
              <span class="${theme}_saveValue">${formattedSaveUpTo}</span>
            </div>
          ` : ''}
        </div>
      `
    }

    //Product color variants
    component.colorVariants = (itemVariants, id, theme) => {
      if(!itemVariants[`product_${id}`]){
        return '';
      }
      const variants = itemVariants[`product_${id}`].variants;
      const availableVariants = [];

      if (!variants) {
        return '';
      }

      variants.forEach(variant => {
        if (!variant.inStock) {
          return '';
        }
        const arrayWithColorOption = variant.choices.filter(choice => choice.optionKey === 'Colour' && choice.colour);
        if (arrayWithColorOption.length) {
          const choiceWithColor = arrayWithColorOption[0];
          if (!availableVariants.find(entry => entry.colour === choiceWithColor.colour)) {
            availableVariants.push({url: variant.product.url, sku: variant.product.sku, colour : choiceWithColor.colour, title : choiceWithColor.title})
          }
        }
      });

      if (!availableVariants.length) {
        return '';
      }
      else {
        let variantList = availableVariants.map((variant) => {
          return `
            <li class="colorSwatches_swatch" data-option-id=${variant.sku} data-option-text=${variant.title} data-context="general-colorSwatchClicked">
              <a href=${variant.url} title=${variant.title} aria-label=${variant.title} >
                <div class="colorSwatches_outer_circle">
                  <div class="colorSwatches_inner_circle " style="background-color: ${variant.colour}"/></div>
                </div>
              </a>
            </li>`}).join("");

        return `
          <ul aria-label="Available Colour Options" class="${theme}_colorSwatches">
            ${variantList}
          </ul>`
      }
    }

    //Product reviews
    component.reviewStars = (item, theme) => {
      const reviewStars = [1, 2, 3, 4 ,5];
      const reviewStarsCount = Number(item.details.review_count);

      if(reviewStarsCount === 0){
        return ''
      }

      return `
        <div class="${theme}_rating">
          <span class="visually-hidden ${theme}_rating_hiddenLabel"> ${item.details.average_review_rating} Stars ${item.details.review_count} Reviews</span>
            <span class="productBlock_ratingStarsContainer">
              <span class="${theme}_ratingStars" style="width:${item.details.average_review_rating*100/5}%">
                ${reviewStars.map((reviewStar) => `
                <span>
                  <svg class="${theme}_reviewStar" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <polygon points="13 0 9.47916667 8.80208333 0 9.4453125 7.27864583 15.5390625 4.9765625 24.7135417 13 19.6692708 21.0234375 24.7135417 18.7213542 15.5390625 26 9.4453125 16.5208333 8.80208333"></polygon>
                  </svg>
                </span>
                `.trim()).join('')}
              </span>
            </span>
            <span class="${theme}_ratingValue" aria-hidden="true">${item.details.average_review_rating}</span>
            <span class="${theme}_reviewCount" aria-hidden="true">${item.details.review_count}</span>
        </div>
      `
    }

    //Product pap banner
    component.papBanner = (item, itemVariants, theme) => {
      if(!itemVariants[`product_${item.details.id}`]){
        return '';
      }
      const marketedSpecialOffer = itemVariants[`product_${item.details.id}`].marketedSpecialOffer;

      if (!marketedSpecialOffer) {
        return '';
      }

      return `
        <div class="${theme}_papBanner" data-pap-block>
          <div class="papBannerWrapper" data-component="papBanner">
            <button data-read-more-translation="read more" class="papBanner papBanner_popupLink" data-js-element="popupLink" data-product-id="${item.details.id}" data-event-type="clickthrough">
              <span class="papBanner_text" id="pap-banner-text-value">
                ${(siteObj.config.useOfferFlagInline) ? `<svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" class="papBanner_label">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-11.000000, -11.000000)" fill="#333333">
                      <path d="M11.0028076,11 L19,11 L29,21 L21,29 L11.0028076,18.9951172 L11.0028076,11 Z M16,18 C17.1045695,18 18,17.1045695 18,16 C18,14.8954305 17.1045695,14 16,14 C14.8954305,14 14,14.8954305 14,16 C14,17.1045695 14.8954305,18 16,18 Z"></path>
                    </g>
                  </g>
                </svg>` : ''}
                ${component.extractPapAttribute(marketedSpecialOffer.title.content[0].content)}
              </span>
            </button>
            <div class="papPopup" data-js-element="papPopup">
              <div class="papPopup_container" data-pappopup-contents="">
                <h3 class="papPopup_title" id="pap-popup-title-value" data-popup-title='${component.extractPapAttribute(marketedSpecialOffer.title.content[0].content)}'>${component.extractPapAttribute(marketedSpecialOffer.title.content[0].content)}</h3>
                <div class="papPopup_text" id="pap-popup-text-value" data-popup-text='${component.extractPapAttribute(marketedSpecialOffer.description.content[0].content)}'>${component.extractPapAttribute(marketedSpecialOffer.description.content[0].content)}</div>
                ${(marketedSpecialOffer.landingPageLink.url) && (marketedSpecialOffer.landingPageLink.text) ? `
                  <a href='${marketedSpecialOffer.landingPageLink.url}' class="papPopup_link" id="pap-popup-link-value" data-popup-link='${marketedSpecialOffer.landingPageLink.text}'>${marketedSpecialOffer.landingPageLink.text}</a>
                ` : ''}
                </div>
            </div>
          </div>
        </div>
      `
    }

    //Quick buy button
    component.quickBuy = (item, theme) => {
      return `
        <div class="${theme}_actions" data-product-actions>
          <span data-component="productQuickbuy"></span>
          <button class="${theme}_button ${theme}_button-moreInfo" data-product-id="${item.details.id}" data-site-id="${siteObj.siteID}" data-default-locale="${siteObj.siteDefaultLocale}" data-subsite-code="${siteObj.subsiteCode}" data-from-wishlist="false" data-from-foundation-matcher="false" data-component="productQuickbuy" data-sku="${item.details.id}" data-open-product-quickbuy data-event-type="clickthrough">
            ${productBlockButtonText}
            <span class="visually-hidden">${item.details.name}</span>
          </button>
        </div>
      `
    }

    // Qubit tracking clickThrough
    component.clickThrough = (callbackData, theme) => {
      const products = [];
      const clickThrough = document.querySelectorAll(component.config.selectors.clickThrough);

      clickThrough.forEach(event => {
        event.addEventListener('click', (e) => {
          e.preventDefault();
          const href  = event.hasAttribute('data-open-product-quickbuy') ? null : event.href;
          const productBlock = event.closest(`.${theme}`)
          const product = productBlock ? productBlock.querySelector(component.config.selectors.productId) : null;
          if(!product && !href){
            return;
          }else if(!product && href){
            $window.location = href;
          }
          const productId = product.dataset.productId;

          if(products.indexOf(productId) === -1){
            products.push(productId);
            component.clickTracking(callbackData, products, productId, href);
          }else{
            if(href){
              $window.location = href;
            }
          }
        });
      });
    };

    component.clickTracking = async (callbackData, products, productId, href) => {
      let params = new URLSearchParams({
        d: callbackData,
        t: "clickthrough",
        ts: Date.now(),
        gcid: component.gaClientId(),
        debug: "qp"
      });

      let paramsWithId = new URLSearchParams({
        d: callbackData,
        t: "clickthrough",
        ts: Date.now(),
        gcid: component.gaClientId(),
        p: productId,
        debug: "qp"
      });

      let promises = [
        (products.length === 1) ? fetch(component.config.urls.qubitTrackingUrl + params) : '',
        fetch(component.config.urls.qubitTrackingUrl + paramsWithId)
        ];

      await Promise.allSettled(promises).then((responses) => {
        responses.forEach((response) => {
          if (response.ok) {
            console.log('success');
          }
        });
      }).catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
      });

      if(href){
        window.location = href;
      }
    };

    // Qubit tracking impression
    component.impression = (callbackData, onViewPort) => {
      const impression = document.querySelector(onViewPort);
      if(!impression){
        return;
      }

      var observer = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          component.impressionTracking(callbackData, null)
          observer.unobserve(impression)
        }
        }, { threshold: [0.1] });

      observer.observe(impression);
    }

    component.impressionProducts = (callbackData, placementId) => {
      const impressionProducts = document.querySelector(`[data-placement="${placementId}"]`);
      if(!impressionProducts){
        return;
      }
      const products= impressionProducts.querySelectorAll(component.config.selectors.productId);
      const productIds = [];
      products.forEach((product) => {
        let id = product.dataset.productId;
        if(productIds.indexOf(id) === -1){
          productIds.push(id);
        }
      });

      var observer = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true){
          component.impressionTracking(callbackData, productIds)
          observer.unobserve(impressionProducts)
        }
        }, { threshold: [0.1] });

      observer.observe(impressionProducts);
    }

    component.impressionTracking = async (callbackData, productIds) => {
      let params = new URLSearchParams({
        d: callbackData,
        t: "impression",
        ts: Date.now(),
        gcid: component.gaClientId(),
        debug: "qp"
      });

      let paramsWithId = new URLSearchParams({
        d: callbackData,
        t: "impression",
        ts: Date.now(),
        gcid: component.gaClientId(),
        debug: "qp"
      });

      const impression = document.querySelector(component.config.selectors.onViewPort);
      if(impression){
        impression.remove();
      }

      if(productIds){
        productIds.map( id => {
          paramsWithId.append('p', id);
        });
      }

      let promises = [
        (impression) ? fetch(component.config.urls.qubitTrackingUrl + params) : '',
        (productIds) ? fetch(component.config.urls.qubitTrackingUrl + paramsWithId) : ''
        ];

      await Promise.allSettled(promises).then((responses) => {
        responses.forEach((response) => {
          if (response.ok) {
            console.log('success');
          }
        });
      }).catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
      });
    };

    // Get GA client ID
    component.gaClientId = () => {
        try {
           var tracker = ga.getAll()[0];
           return tracker.get('clientId');
        }
        catch(e) {
           return;
        }
    }

    //Get cookie consent
    component.getCookieConsent = () => {
      let element = document.querySelector('[data-placement]');
      let bannerAcceptButton = document.getElementById('onetrust-accept-btn-handler');
      let pcAllowAllButton = document.getElementById('accept-recommended-btn-handler');
      let pcSaveButton = document.getElementsByClassName('save-preference-btn-handler onetrust-close-btn-handler')[0];

      if (!bannerAcceptButton && !pcAllowAllButton && !pcSaveButton) {
        attempts += 1;
        if (attempts > 30) {
          return;
        }
        setTimeout(component.getCookieConsent, 100);
      }else{
        if(bannerAcceptButton){
          bannerAcceptButton.addEventListener('click', (e) => { component.init(element); });
        }
        if(pcAllowAllButton){
          pcAllowAllButton.addEventListener('click', (e) => { component.init(element); });
        }
        if(pcSaveButton){
          pcSaveButton.addEventListener('click', (e) => { component.init(element); });
        }
        return;
      }
    }

    //Qubit tracker cookie
    component.setCookie = (cookieValue) => {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      const path = '/';
      let domain;
      if($window.location.host.indexOf('www') === 0){
        domain = siteUrl.replace('www','');
      }else{
        domain = `.${siteUrl}`;
      }
      if (!cookie.get(qubitCookieName)) {
        document.cookie =  `${qubitCookieName}=${cookieValue}; expires=${expirationDate.toUTCString()}; domain=${domain}; path=${path};`;
      }
    }

    return component;
  };

  return qubitProductPageRecs;
});
